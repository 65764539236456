import Title from "./../components/section/Title";
import { useTranslation } from "react-i18next";
import ButtonMoveSectionDown from "../components/buttons/ButtonMoveSectionDown";
import SocialMediaBar from "../components/socialmediabar/SocialMediaBar";

export default function SectionHiImNick({ fullpageApi }) {
  const [t] = useTranslation("sections");

  return (
    <div className="content-wrapper">
      <Title text={t("hiimnick.title")} />
      <SocialMediaBar />
      <ButtonMoveSectionDown fullpageApi={fullpageApi} />
    </div>
  );
}
