import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fullpageSectionNames } from "./../../constants/fullpage";
import NavigationItem from "./NavigationItem";

export default function Navigation() {
  const location = useLocation();
  const [active, setActive] = useState(" ");

  useEffect(() => {
    if (location.hash) setActive(location.hash.split("#")[1]);
    return () => {};
  }, [location]);

  return (
    <ul className="navigation">
      {fullpageSectionNames.map((_name, index) => {
        return <NavigationItem key={index} active={active} index={index} />;
      })}
    </ul>
  );
}
