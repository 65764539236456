export const fullpageLicenseKey = "2ACAF0A5-BDCA4C75-8E819FEB-9B787F3D";
export const fullpageSectionName = "section";
export const fullpageAnchors = [
  "hi-im-nick",
  "nice-to-meet-you",
  "i-like-to-code",
  "my-cv",
  "see-you-soon",
];
export const fullpageSectionNames = [
  "hiimnick",
  "nicetomeetyou",
  "iliketocode",
  "mycv",
  "seeyousoon",
];
