import Title from "./../components/section/Title";
import { useTranslation } from "react-i18next";
import Emoji from "../components/section/Emoji";
import Copyright from "../components/other/Copyright";

export default function SeeYouSoon() {
  const [t] = useTranslation("sections");

  return (
    <>
      <div className="content-wrapper">
        <Title text={t("seeyousoon.title")} />
        <p className="content-subtitle">
          <Emoji name="em em-beers" />
        </p>
        <Copyright />
      </div>
    </>
  );
}
