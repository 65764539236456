import { useTranslation } from "react-i18next";
import {
  fullpageAnchors,
  fullpageSectionNames,
} from "./../../constants/fullpage";

export default function NavigationItem({ active = false, index = 0 }) {
  const [t] = useTranslation("global");

  return (
    <li
      className={
        index === 0 && active === " "
          ? "active"
          : active === fullpageAnchors[index]
          ? "active"
          : ""
      }
    >
      <a href={`/#${fullpageAnchors[index]}`}>
        {t(`navigation.${fullpageSectionNames[index]}`)}
      </a>
    </li>
  );
}
