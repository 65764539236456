import moment from "moment";

/**
 * Calculates the age based on the date of birth
 * @param {Date} dob Date of birth
 * @returns age
 */
export const getAge = (dob) => {
  var birthday = +new Date(dob);
  return ~~((Date.now() - birthday) / 31557600000);
};

/**
 * Gives back the current year
 * @returns current year
 */
export const getCurrentYear = () => moment().format("YYYY");
