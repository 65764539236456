import global_en from "./en/global.json";
import sections_en from "./en/sections.json";

import global_nl from "./nl/global.json";
import sections_nl from "./nl/sections.json";

const en = {
  global: global_en,
  sections: sections_en,
};

const nl = {
  global: global_nl,
  sections: sections_nl,
};

export const languages = {
  en: en,
  nl: nl,
};
