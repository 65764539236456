import Title from "./../components/section/Title";
import { useTranslation } from "react-i18next";
import Emoji from "../components/section/Emoji";
import { getAge } from "./../helpers/dates";

export default function NiceToMeetYou() {
  const [t] = useTranslation("sections");
  const age = getAge(new Date("2000-09-13 00:00:00"));

  return (
    <>
      <div className="content-wrapper">
        <Title text={t("nicetomeetyou.title")} />
        <p className="content-subtitle">
          <Emoji name="em em-man-raising-hand" />
        </p>
        <p className="content-text">
          I'm a {age} year old young{" "}
          <i className="em em-man" aria-label="Man"></i> from{" "}
          <i className="em em-flag-be" aria-label="Belgium"></i> . <br />
          Ever since I can remember, I find everything with technology super
          interesting <i className="em em-bangbang" aria-label="!"></i> <br />
          And like most millennials, I have an obsession with{" "}
          <i className="em em-pizza" aria-label="pizza"></i> and my{" "}
          <i className="em em-iphone" aria-label="phone"></i> .
        </p>
      </div>
    </>
  );
}
