import Title from "./../components/section/Title";
import { useTranslation } from "react-i18next";
import Emoji from "../components/section/Emoji";

export default function ILikeToCode() {
  const [t] = useTranslation("sections");

  return (
    <>
      <div className="content-wrapper">
        <Title text={t("iliketocode.title")} />
        <p className="content-subtitle">
          <Emoji name="em em-computer" />
        </p>
        <p className="content-text">
          Currently I'm a student <br /> @{" "}
          <a
            className="link"
            target="_blank"
            href="https://www.ucll.be/"
            rel="noreferrer"
          >
            University Colleges Leuven-Limburg
          </a>
          , <i className="em em-flag-be" aria-label="Belgium"></i>
        </p>
        <p className="content-text">
          After these <i className="em em-three" aria-label="3"></i> years, I am
          starting a new chapter in my life.
        </p>
      </div>
    </>
  );
}
