import "./scss/style.scss";
import { useTranslation } from "react-i18next";
import Logo from "./components/navigation/Logo";
import Navigation from "./components/navigation/Navigation";

// FullPage
import ReactFullpage from "@fullpage/react-fullpage";
import {
  fullpageLicenseKey,
  fullpageAnchors,
  fullpageSectionNames,
} from "./constants/fullpage";

// Sections
import HiImNick from "./sections/HiImNick";
import NiceToMeetYou from "./sections/NiceToMeetYou";
import ILikeToCode from "./sections/ILikeToCode";
import MyCv from "./sections/MyCv";
import SeeYouSoon from "./sections/SeeYouSoon";

export default function App() {
  const [t] = useTranslation("global");

  /**
   * Extensions fullpage
   */
  const pluginWrapper = () => {};

  /**
   * Navigation tooltips
   */
  const mapFullpageNavigationTooltips = () =>
    fullpageSectionNames.map((tip) => t(`tooltips.${tip}`));

  /**
   * Fullpage Config
   */
  const fullPageConfig = {
    pluginWrapper: pluginWrapper,
    licenseKey: fullpageLicenseKey,
    navigation: true,
    navigationPosition: "right",
    navigationTooltips: mapFullpageNavigationTooltips(),
    scrollingSpeed: 1000,
    anchors: fullpageAnchors,
    scrollBar: false,
  };

  return (
    <>
      <Logo />
      <Navigation />
      <ReactFullpage
        {...fullPageConfig}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <HiImNick fullpageApi={fullpageApi} />
              </div>
              <div className="section">
                <NiceToMeetYou />
              </div>
              <div className="section">
                <ILikeToCode />
              </div>
              <div className="section">
                <MyCv />
              </div>
              <div className="section">
                <SeeYouSoon />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </>
  );
}
