import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

export default function ButtonMoveSectionDown({ fullpageApi }) {
  library.add(faAngleDoubleDown);

  const onClick = () => {
    if (fullpageApi) {
      fullpageApi.moveSectionDown();
    }
  };

  return (
    <div className="content-text">
      <FontAwesomeIcon
        icon={["fas", "angle-double-down"]}
        size="2x"
        onClick={onClick}
        className="button-movesectiondown"
      />
    </div>
  );
}
