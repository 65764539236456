import SocialMediaItem from "./SocialMediaItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
  faDiscord,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

export default function SocialMediaBar() {
  /**
   * Add Icons to FontAwesome Library
   */
  const addIconsToLibrary = () => {
    library.add(faLinkedinIn);
    library.add(faEnvelope);
    library.add(faAddressCard);
    library.add(faDiscord);
    library.add(faGithub);
  };

  /**
   * @type {Array<{icon: FontAwesomeIcon, link: string, options: {}}>}
   */
  const iconLinks = [
    {
      icon: <FontAwesomeIcon icon={["fab", "linkedin-in"]} size="2x" />,
      link: "https://www.linkedin.com/in/nickdewyngaert/",
      options: {
        target: "_blank",
      },
    },
    {
      icon: <FontAwesomeIcon icon={["fas", "envelope"]} size="2x" />,
      link: "mailto:contact@nickdewyngaert.be",
      options: {
        target: "_blank",
      },
    },
    {
      icon: <FontAwesomeIcon icon={["fas", "address-card"]} size="2x" />,
      link: "https://cv.nickdewyngaert.be",
      options: {
        target: "_blank",
      },
    },
    {
      icon: <FontAwesomeIcon icon={["fab", "discord"]} size="2x" />,
      link: "https://discordapp.com/users/829454422169223229/",
      options: {
        target: "_blank",
      },
    },
    {
      icon: <FontAwesomeIcon icon={["fab", "github"]} size="2x" />,
      link: "https://github.com/NickDeWyngaert",
      options: {
        target: "_blank",
      },
    },
  ];

  addIconsToLibrary();

  return (
    <div className="social-media">
      {iconLinks.map((item, index) => (
        <SocialMediaItem key={index} {...item} />
      ))}
    </div>
  );
}
