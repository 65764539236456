import Title from "./../components/section/Title";
import { useTranslation } from "react-i18next";
import Emoji from "../components/section/Emoji";

export default function MyCv() {
  const [t] = useTranslation("sections");

  return (
    <>
      <div className="content-wrapper">
        <Title text={t("mycv.title")} />
        <p className="content-subtitle">
          <a
            className="link unmarked"
            target="_blank"
            href="https://cv.nickdewyngaert.be"
            rel="noreferrer"
          >
            <Emoji name="em em-bookmark_tabs" />
          </a>
        </p>
        <p className="content-text">
          Do you want to get to know me better? <br />
          Then be sure to fill in the contact form on my CV page.
        </p>
      </div>
    </>
  );
}
