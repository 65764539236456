import i18next from "i18next";
import { languages } from "./languages";
import {
  translationFallback,
  translationLang,
} from "./../constants/translation";

export const setupTranslations = () => {
  i18next.init({
    interpolation: { escapeValue: false }, // React does escaping for us
    lng: translationLang,
    fallbackLng: translationFallback,
    returnObjects: true,
    resources: languages,
  });
};
